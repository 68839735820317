import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { DadosContext } from '../common/DadosContext';
import { CircularProgress, Button } from '@mui/material';
import DateFilter from './DateFilter';
import PotentialProductsAndAnomalies from './PotentialProductsAndAnomalies';
import { agruparVendasPorData, detectarAnomalias, previsaoVendasTensorFlow } from './utils/analytics';
import SalesChart from './SalesChart';
import PredictionsChart from './PredictionsChart';
import AnomaliesChart from './AnomaliesChart';
import MovingAverageChart from './MovingAverageChart';

import TopProductsCard from './TopProductsCard';
import { Loarder } from '../components/layout/Loader';
import CurvaABCTable from './CurvaABCTable';
import { useAuth } from '../common/AuthContext';

// Componente de Card Informativo
const InfoCard = ({ title, value }) => (
    <div className="bg-zinc-900 shadow-md rounded-lg p-4 text-center">
        <h3 className="text-lg font-bold text-gray-700">{title}</h3>
        <p className="text-2xl text-blue-500 font-semibold">{value}</p>
    </div>
);

const PedidoMagalu = () => {
    const { accessCodeMagalu} = useAuth();
    const { tokens } = useContext(DadosContext);
    const [userData, setUserData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [previsoes, setPrevisoes] = useState([]);
    const [mediaMovel, setMediaMovel] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [startDate, setStartDate] = useState(dayjs().startOf('year').toDate());
    const [endDate, setEndDate] = useState(dayjs().endOf('month').toDate());
    const [topProducts, setTopProducts] = useState({
        topMaisVendidos: [],
        topMenosVendidos: [],
        topTendencias: [],
    });

    const fetchOrders = async () => {
        const token = tokens.find((t) => t.id === 'magalu')?.token;

        if (!token) {
            setError('Token não encontrado');
            setLoading(false);
            return;
        }
        try {
            const response = await axios.post('https://nerp.com.br/magalu/orders', {

                token: accessCodeMagalu,

                params: {
                    _sort: 'purchased_at:desc', // Parâmetro opcional
                },
            });

            console.log(response.data);
        } catch (error) {
            console.error('Erro ao buscar pedidos:', error.response?.data || error.message);
        }
    };



    const fetchFilteredOrders = async () => {
        setLoading(true);
        setError(null);
        const token = tokens.find((t) => t.id === 'magalu')?.token;

        if (!token) {
            setError('Token não encontrado');
            setLoading(false);
            return;
        }

        try {

            const response = await axios.post(
                'https://nerp.com.br/magalu/pedidos',
                {
                    accessToken: accessCodeMagalu,
                    startDate: startDate,
                    endDate: endDate,
                },
                { headers: { 'Content-Type': 'application/json' } }
            );


            const orders = response.data.map((order) => ({
                id: order.id,
                createdAt: order.created_at,
                purchasedAt: order.purchased_at,
                total: order.agreement?.total?.items / 100 || 0,
                sku: order.agreement?.items.map((item) => ({
                    sku: item.sku?.code || 'SKU desconhecido', // Garantindo que sempre tenha um SKU
                })),
                frete: order.agreement.total.deliveries / 100 || 0,
                desconto: order?.agreement?.total?.discounts ? order.agreement.total.discounts / 100 : 0,

            }));
            console.log(response.data)
            setUserData(orders);
            setTopProducts(response.data);


        } catch (error) {
            setError('Erro ao buscar pedidos: ' + (error.response?.data?.message || error.message));
        } finally {
            setLoading(false);
        }
    };

    const calcularTopProdutos = (data) => {
        const vendasPorProduto = data.reduce((acc, order) => {
            if (order.sku && Array.isArray(order.sku)) {
                order.sku.forEach((item) => {
                    const sku = item.sku || 'SKU desconhecido';
                    const total = order.total || 0;

                    if (!acc[sku]) {
                        acc[sku] = { sku, quantidade: 0, total: 0 };
                    }

                    acc[sku].quantidade += 1; // Cada item é contado como uma venda
                    acc[sku].total += total; // Incrementa o total do pedido
                });
            }
            return acc;
        }, {});

        const produtosOrdenados = Object.values(vendasPorProduto)
            .sort((a, b) => b.quantidade - a.quantidade);

        return {
            topMaisVendidos: produtosOrdenados.slice(0, 500),
            topMenosVendidos: produtosOrdenados.slice(-20).reverse(),
            topTendencias: produtosOrdenados.slice(0, 20), // Personalize conforme sua lógica
        };
    };

    useEffect(() => {
        fetchOrders();
    }, [tokens]);


    useEffect(() => {
        if (userData.length) {
            const topProdutosCalculados = calcularTopProdutos(userData);
            setTopProducts(topProdutosCalculados);
        }
    }, [userData]);

    const processarDadosProdutos = (dados) => {
        return dados.reduce((acc, order) => {
            // Cada pedido pode ter múltiplos itens (SKUs)
            if (order.sku && Array.isArray(order.sku)) {
                order.sku.forEach((item) => {
                    const sku = item.sku || 'SKU desconhecido';
                    if (!acc[sku]) {
                        acc[sku] = [];
                    }
                    acc[sku].push({
                        total: order.total || 0,
                        date: order.createdAt || order.purchasedAt || '',
                        quantity: 1, // Cada item conta como 1 unidade
                    });
                });
            }
            return acc;
        }, {});
    };


    const calcularMediaMovel = (dados, tamanhoJanela = 7) => {
        if (dados.length < tamanhoJanela) return [];
        const mediaMovel = [];

        for (let i = 0; i <= dados.length - tamanhoJanela; i++) {
            const janela = dados.slice(i, i + tamanhoJanela).map(item => item.total);
            const media = janela.reduce((acc, val) => acc + val, 0) / tamanhoJanela;
            mediaMovel.push({
                date: dados[i + tamanhoJanela - 1].date,
                media,
            });
        }

        return mediaMovel;
    };

    useEffect(() => {
        if (userData.length) {
            const dadosAgrupados = agruparVendasPorData(userData);
            const dadosComAnomalias = detectarAnomalias(dadosAgrupados);
            setFilteredData(dadosComAnomalias);
            previsaoVendasTensorFlow(dadosAgrupados, setPrevisoes);

            // Calcular média móvel
            const mediaMovelCalculada = calcularMediaMovel(dadosAgrupados);
            setMediaMovel(mediaMovelCalculada);
        }
    }, [userData]);


    // Cálculos para os cards
    const totalVendas = filteredData.reduce((acc, item) => acc + item.total, 0);
    const mediaVendasDiaria =
        filteredData.length > 0
            ? (filteredData.reduce((acc, item) => acc + item.total, 0) / filteredData.length).toFixed(2)
            : 0;
    const diasComAnomalias = filteredData.filter((item) => item.isAnomalia).length;
    const maiorVenda = filteredData.length > 0
        ? filteredData.reduce((max, item) => (item.total > max.total ? item : max), filteredData[0])
        : null;
    const previsaoTotal = previsoes.reduce((acc, value) => acc + value, 0).toFixed(2);

    const productData = processarDadosProdutos(userData);

    // Total de vendas na data de hoje
    const vendasHoje = filteredData
        .filter((item) => dayjs(item.date).isSame(dayjs(), 'day'))
        .reduce((acc, item) => acc + item.total, 0);

    const totalDesconto = userData.reduce((acc, item) => acc + item.desconto, 0);
    const totalFrete = userData.reduce((acc, item) => acc + item.frete, 0);

    return (
        <div className="px-4 py-36 bg-gradient-to-r from-gray-900 via-gray-800 to-gray-700 text-white min-h-screen">
            {loading ? (
                <Loarder />
            ) : error ? (
                <div className="bg-red-700 p-6 rounded-lg shadow-lg mb-8">
                    <p className="text-white font-semibold">{error}</p>
                    <Button
                        variant="contained"
                        color="error"
                        onClick={fetchFilteredOrders}
                        className="mt-4 bg-red-500 hover:bg-red-600 transition duration-300 ease-in-out shadow-lg"
                    >
                        Tentar Novamente
                    </Button>

                    <Button
                        variant="contained"
                        color="error"
                        onClick={fetchOrders}
                        className="mt-4 bg-red-500 hover:bg-red-600 transition duration-300 ease-in-out shadow-lg"
                    >
                        Tentar Novamente
                    </Button>
                </div>
            ) : (
                <div>
                    <header className="flex justify-between items-center mb-8">
                        <h1 className="text-4xl font-bold text-white">Dashboard de Vendas</h1>
                        <div className="flex items-center gap-4">
                            <DateFilter
                                startDate={startDate}
                                setStartDate={setStartDate}
                                endDate={endDate}
                                setEndDate={setEndDate}
                            />
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={fetchFilteredOrders}
                                disabled={loading}
                                className="bg-blue-600 hover:bg-blue-700 text-white transition duration-300 ease-in-out rounded-lg px-6 py-2"
                            >
                                Buscar Pedidos
                            </Button>

                            <Button
                                variant="contained"
                                color="primary"
                                onClick={fetchOrders}
                                disabled={loading}
                                className="bg-blue-600 hover:bg-blue-700 text-white transition duration-300 ease-in-out rounded-lg px-6 py-2"
                            >
                                Buscar Pedidos2
                            </Button>
                        </div>
                    </header>

                    {/* Cards de Métricas Melhorados */}
                    <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-6 mb-8">
                        {[
                            {
                                title: "Total de Vendas",
                                value: `R$ ${totalVendas.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                                gradient: "from-blue-500 via-purple-600 to-pink-500"
                            },
                            {
                                title: "Média Diária",
                                value: `R$ ${mediaVendasDiaria.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                                gradient: "from-green-500 via-teal-600 to-blue-500"
                            },
                            {
                                title: "Dias com Anomalias",
                                value: diasComAnomalias,
                                gradient: "from-yellow-500 via-orange-600 to-red-500"
                            },
                            {
                                title: "Maior Venda",
                                value: maiorVenda ? `R$ ${maiorVenda.total.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}` : 'N/A',
                                gradient: "from-teal-500 via-blue-600 to-indigo-500"
                            },
                            {
                                title: "Previsão de 30 dias",
                                value: `R$ ${previsaoTotal.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                                gradient: "from-pink-500 via-purple-600 to-blue-500"
                            },
                            {
                                title: "Vendas Hoje",
                                value: `R$ ${vendasHoje.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                                gradient: "from-indigo-500 via-blue-600 to-teal-500"
                            },
                            {
                                title: "Total de Descontos",
                                value: `R$ ${totalDesconto.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                                gradient: "from-gray-500 via-gray-600 to-gray-700"
                            },
                            {
                                title: "Total de Frete",
                                value: `R$ ${totalFrete.toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`,
                                gradient: "from-cyan-500 via-blue-600 to-green-500"
                            }
                        ].map((card, index) => (
                            <div key={index} className={`bg-gradient-to-r ${card.gradient} p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out`}>
                                <p className="text-lg font-semibold text-white">{card.title}</p>
                                <p className="text-3xl font-bold text-white">{card.value}</p>
                            </div>
                        ))}
                    </div>

                    {/* Gráficos */}
                    <div className="fundo-card p-6 rounded-lg shadow-lg mb-8">
                        <SalesChart data={filteredData} />
                    </div>


                    <div className="w-full lg:flex grid-cols-1 md:grid-cols-3 gap-6 mb-8">
                        <div className="lg:w-1/3 fundo-card p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out">
                            <p className="text-lg font-semibold text-white">Previsão de Venda</p>
                            <PredictionsChart previsoes={previsoes} />
                        </div>
                        <div className="lg:w-1/3 fundo-card p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out">
                            <p className="text-lg font-semibold text-white">Anomalias</p>
                            <AnomaliesChart data={filteredData} />
                        </div>
                        <div className="lg:w-1/3 fundo-card p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out">
                            <p className="text-lg font-semibold text-white">Média Móvel</p>
                            <MovingAverageChart data={mediaMovel} />
                        </div>
                    </div>

                    <CurvaABCTable products={topProducts.topMaisVendidos || []} />

                    <PotentialProductsAndAnomalies data={topProducts.topMaisVendidos} />;

                    {/* Top Produtos */}
                    {/* <div className="rounded-lg shadow-lg mb-8">
                        <TopProductsCard
                            title="Top Produtos"
                            products={topProducts.topMaisVendidos || []}
                            productData={productData || {}}
                        />
                    </div> */}
                </div>
            )}
        </div>
    );



};

export default PedidoMagalu;
