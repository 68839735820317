import React from 'react';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const AnomaliesChart = ({ data }) => {
  const anomalies = data.filter((item) => item.isAnomalia);

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={anomalies}>
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey="total" stroke="#ff7300" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default AnomaliesChart;
