import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { DadosContext } from '../../common/DadosContext';

// Função para definir a cor da linha com base no tempo decorrido desde o primeiro evento de "COLETA SOLICITADA"
const getStatusColor = (status, eventDate) => {
    if (status !== 'COLETA SOLICITADA') return 'bg-gray-100 text-gray-800';

    const currentDate = new Date();
    const diffTime = Math.abs(currentDate - new Date(eventDate));
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays <= 9) {
        return 'bg-green-100 text-green-800';
    } else if (diffDays <= 14) {
        return 'bg-yellow-100 text-yellow-800';
    } else {
        return 'bg-red-100 text-red-800';
    }
};

// Função para formatar a data e hora no formato dd/mm/yy hh:mm
const formatDateTime = (dateString) => {
    if (!dateString) return '';
    const options = {
        day: '2-digit',
        month: '2-digit',
        year: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
    };
    return new Date(dateString).toLocaleString('pt-BR', options);
};

// Função para converter a data em um formato comparável
const parseDate = (dateString) => {
    return dateString ? new Date(dateString) : new Date(0);
};

const ListaLogistica = () => {
    const { dataCarregada, setDataCarregada, updateLogJadlog, tokens } = useContext(DadosContext);
    const [filterInput, setFilterInput] = useState("");
    const [updating, setUpdating] = useState(false);
    const [error, setError] = useState(null);
    const [selectedItem, setSelectedItem] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Definir colunas da tabela
    const columns = React.useMemo(
        () => [
            { Header: 'Código', accessor: 'jadlog.codigo' },
            { Header: 'Shipment ID', accessor: 'jadlog.shipmentId' },
            { Header: 'Status', accessor: 'jadlog.tracking.status' },
            { Header: 'Rastreio', accessor: 'ultimaUnidade' },
            { Header: 'Data', accessor: 'ultimaData' },
            { Header: 'Eventos', accessor: 'eventos' },
            { Header: 'Anotações', accessor: 'anotacoes' },
        ],
        []
    );

    // Função para consultar a API da Jadlog
    const consultarJadlog = async (codigo) => {
        const token = tokens.find(token => token.id === 'jadlog')?.token;
        if (!token) {
            throw new Error('Token não encontrado');
        }
        
        try {
            const response = await axios.post('/jadlog/tracking/consultar/codigo', {
                token: token,
                codigo: codigo,
            });
            if (response.data.consulta && response.data.consulta[0].erro) {
                throw new Error(response.data.consulta[0].erro.descricao);
            }
            return response.data.consulta[0];
        } catch (error) {
            console.error(`Erro ao consultar código ${codigo}:`, error);
            throw error;
        }
    };

    // Função para atualizar o banco de dados local
    const atualizarBancoLocal = async (id, dadosAtualizados) => {
        try {
            await updateLogJadlog(id, dadosAtualizados);
            console.log(`Dados atualizados para o item com id ${id}:`, dadosAtualizados);
        } catch (error) {
            console.error(`Erro ao atualizar dados para o item com id ${id}:`, error);
            throw error;
        }
    };

    // Função para atualizar todos os códigos
    const updateAllCodes = async () => {
        setUpdating(true);
        setError(null);
        try {
            const updatedData = await Promise.all(dataCarregada.map(async (nota) => {
                try {
                    const dadosAtualizados = await consultarJadlog(nota.jadlog.codigo);
                    const ultimoEvento = dadosAtualizados.tracking.eventos[dadosAtualizados.tracking.eventos.length - 1];
                    const notaAtualizada = { 
                        ...nota, 
                        jadlog: { 
                            ...nota.jadlog, 
                            tracking: dadosAtualizados.tracking 
                        },
                        ultimaUnidade: ultimoEvento?.unidade || '',
                        ultimaData: ultimoEvento?.data || ''
                    };
                    await atualizarBancoLocal(nota.id, notaAtualizada);
                    return notaAtualizada;
                } catch (error) {
                    console.error(`Erro ao atualizar código ${nota.jadlog.codigo}: ${error.message}`);
                    return nota;
                }
            }));
            setDataCarregada(updatedData);
        } catch (error) {
            setError('Erro ao atualizar todos os códigos.');
        }
        setUpdating(false);
    };

    const handleFilterChange = (e) => {
        const value = e.target.value || "";
        setFilterInput(value);
    };

    const handleAnotacaoChange = async (id, anotacao) => {
        const updatedData = dataCarregada.map((nota) =>
            nota.id === id ? { ...nota, anotacoes: anotacao } : nota
        );
        setDataCarregada(updatedData);
        try {
            await updateLogJadlog(id, { anotacoes: anotacao });
            console.log(`Anotação atualizada para o item com id ${id}: ${anotacao}`);
        } catch (err) {
            console.error('Erro ao atualizar anotação no Firebase:', err);
        }
    };

    const openModal = (item) => {
        setSelectedItem(item);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setSelectedItem(null);
        setIsModalOpen(false);
    };

    if (!dataCarregada) {
        return <div>Carregando dados...</div>;
    }

    // Ordenar os dados pela data mais recente
    const sortedData = dataCarregada.sort((a, b) => parseDate(b.ultimaData) - parseDate(a.ultimaData));

    const filteredData = sortedData.filter((nota) =>
        nota.jadlog.tracking.status.toLowerCase().includes(filterInput.toLowerCase())
    );

    return (
        <div className="">
            <h2 className="text-2xl font-bold mb-4">Lista de Logística</h2>
            <div className='flex justify-between gap-4'>
                <button
                    onClick={updateAllCodes}
                    disabled={updating}
                    className="mb-4 w-1/4 bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
                >
                    {updating ? 'Atualizando...' : 'Atualizar Todos'}
                </button>
                <input
                    className="mb-4 p-2 border border-gray-300 rounded-md w-full"
                    value={filterInput}
                    onChange={handleFilterChange}
                    placeholder={"Filtrar por status"}
                />
            </div>

            {error && <div className="text-red-500 mb-4">Erro: {error}</div>}
            <div className="overflow-x-auto">
                <table className="min-w-full bg-white border border-gray-800">
                    <thead>
                        <tr className="border border-gray-800 bg-gray-100 text-left">
                            {columns.map((column) => (
                                <th key={column.accessor} className="py-2 px-4 border-b border border-gray-800 text-center">
                                    {column.Header}
                                </th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData.map((nota) => (
                            <tr key={nota.id} className={`${getStatusColor(nota.jadlog.tracking.status, nota.primeiroEventoData)}`}>
                                <td className="py-2 px-4 border border-gray-800">{nota.jadlog.codigo}</td>
                                <td className="py-2 px-4 border border-gray-800">{nota.jadlog.shipmentId || 'N/A'}</td>
                                <td className="py-2 px-4 border border-gray-800">{nota.jadlog.tracking.status}</td>
                                <td className="py-2 px-4 border border-gray-800">{nota.ultimaUnidade}</td>
                                <td className="py-2 px-4 border border-gray-800">{formatDateTime(nota.ultimaData)}</td>
                                <td className="py-2 px-4 border border-gray-800 text-center">
                                    <button
                                        onClick={() => openModal(nota)}
                                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-1 px-2 rounded"
                                    >
                                        ver
                                    </button>
                                </td>
                                <td className="py-2 px-4 border border-gray-800">
                                    <input
                                        type="text"
                                        value={nota.anotacoes || ''}
                                        onChange={(e) => handleAnotacaoChange(nota.id, e.target.value)}
                                        className="p-2 border border-gray-300 rounded-md w-full"
                                    />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>

            {isModalOpen && selectedItem && (
                <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
                    <div className="bg-white p-4 rounded shadow-lg w-3/4 max-h-screen overflow-y-auto">
                        <h3 className="text-xl font-bold mb-4">Eventos de {selectedItem.jadlog.codigo}</h3>
                        <ul>
                            {selectedItem.jadlog.tracking.eventos.map((evento, index) => (
                                <li key={index} className="mb-2">
                                    <p><strong>Status:</strong> {evento.status}</p>
                                    <p><strong>Data:</strong> {formatDateTime(evento.data)}</p>
                                    <p><strong>Unidade:</strong> {evento.unidade}</p>
                                </li>
                            ))}
                        </ul>
                        <button
                            onClick={closeModal}
                            className="mt-4 bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded"
                        >
                            Fechar
                        </button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ListaLogistica;
