

import React from 'react';
import ReactDOM from 'react-dom';
import 'firebase/compat/firestore';
import { App } from './App';
import "./index.css"

if (!Array.prototype.at) {
  Array.prototype.at = function (n) {
    // Convert the argument to an integer
    n = Math.trunc(n) || 0;

    // Allow negative indexing from the end
    if (n < 0) n += this.length;

    // Return undefined if the index is out of range
    if (n < 0 || n >= this.length) return undefined;

    // Otherwise, return the item at the specified index
    return this[n];
  };
}


ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);