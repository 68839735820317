import React, { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { DadosContext } from '../../common/DadosContext';

const Conversations = () => {
    const { tokens } = useContext(DadosContext);
  const [conversations, setConversations] = useState([]);
  const [pagination, setPagination] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Filtros
  const [status, setStatus] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  const fetchConversations = async (offset = 0, limit = 20) => {
    // setLoading(true);
    // setError(null);

    // const token = tokens.find((t) => t.id === 'magalu_chat')?.token;

    // if (!token) {
    //     setError('Token não encontrado');
    //     setLoading(false);
    //     return;
    // }

    try {
        const response = await axios.post(
            'https://nerp.com.br/magalu/conversas',
            { 
               
                accessToken: "eyJraWQiOiI2VERTaF9XcHp0VldSZG9DVlZ6emZyZng5OFFQTERUZ1lWSzlBQmdmZUxZIiwiYWxnIjoiUlMyNTYifQ.eyJpc3MiOiJodHRwczovL2F1dG9zZWctaWRwLmx1aXphbGFicy5jb20vIiwiaWF0IjoxNzMzNDk2Nzk4LCJqdGkiOiJmZTY1OTVkZC01NTc4LTQyOTAtYWIxOC1mOWMzZDE0YjllNTAiLCJhdWQiOlsiaHR0cHM6Ly9zZXJ2aWNlcy5tYWdhbHUuY29tIl0sInNjb3BlIjoic2VydmljZXM6cXVlc3Rpb25zLXNlbGxlcjpyZWFkIHNlcnZpY2VzOnF1ZXN0aW9ucy1zZWxsZXI6d3JpdGUgc2VydmljZXM6Y29udmVyc2F0aW9ucy1zZWxsZXI6cmVhZCBzZXJ2aWNlczpjb252ZXJzYXRpb25zLXNlbGxlcjp3cml0ZSIsImF6cCI6IlU2SVZOZmFQS1pYc2tIUjF1QlhaNGltdnBRanhsQndoZVV3dllSVVNvbFEiLCJwcm9maWxpbmciOiJpZG1fYzM1ODkyOTEtOWViMC00OWRjLTljMjItN2FjMGVlNzVjMDJjIiwiYW1yIjpbInBhc3N3b3JkIl0sImV4cCI6MTczMzUwMzk5OCwicGlkIjoiYjE0M2IwNmQtOTg4My00MmI4LWI4YjctMmY4NDI1ZWI4OWZkIiwidGVuYW50IjoiR0VOUFVCLmYzNzU1MGY4LTYzZTItNDEyZi04OTIzLTg4NzUxZGZhMzY4MSIsImVtYWlsIjoiZ3VzdGF2b0Bjb250aGV5LmNvbS5iciIsInN1YiI6Ijc3YjFkMGJkLWI4ZjUtNDFkZi1hOWJkLTBhZGIwNTU2NTNiYiIsInRlbmFudF90aXRsZSI6Im9yZ2FuaXphdGlvbiJ9.FOdHT_VQHyLDJBa0e7zSYBEdQzktpc2ecwJJppSKlBay7ymlud2Xd0EcO634CPu2lA2Cs8Xk1KiXchH7JHqiWhhOkjLnWU-RkxwCD6JDrHg5hJmBf39whpiTuDkTjatvhawf3vuCBP1kuzH46QmpTmQUGOAhz3SFUHEcbFHigyo",
            },            
        );

        const { results, meta } = response.data;

        setConversations(results);
        setPagination(meta.page);
    } catch (err) {
        console.error('Erro ao carregar conversas:', err);
        setError('Erro ao carregar conversas.');
    } finally {
        setLoading(false);
    }
};

  useEffect(() => {
    
  }, []);

  const handleSearch = () => {
    fetchConversations();
  };

  const handlePrevious = () => {
    if (pagination.offset > 0) {
      fetchConversations(pagination.offset - pagination.limit, pagination.limit);
    }
  };

  const handleNext = () => {
    fetchConversations(pagination.offset + pagination.limit, pagination.limit);
  };

  return (
    <div className='p-36'>
      <h1>Conversas</h1>

      {/* Filtros */}
      <div style={{ marginBottom: '20px' }}>
        <label>
          Status:
          <select value={status} onChange={(e) => setStatus(e.target.value)}>
            <option value="">Todos</option>
            <option value="WAITING_RESPONSE">Aguardando Resposta</option>
            <option value="REJECTED_RESPONSE">Resposta Rejeitada</option>
            <option value="APPROVED">Resposta Aprovada</option>
          </select>
        </label>
        <label style={{ marginLeft: '20px' }}>
          Início:
          <input
            type="datetime-local"
            value={startDate}
            onChange={(e) => setStartDate(e.target.value)}
          />
        </label>
        <label style={{ marginLeft: '20px' }}>
          Fim:
          <input
            type="datetime-local"
            value={endDate}
            onChange={(e) => setEndDate(e.target.value)}
          />
        </label>
        <button onClick={handleSearch} style={{ marginLeft: '20px' }}>
          Buscar
        </button>
      </div>

      {/* Resultados */}
      {loading && <p>Carregando...</p>}
      {error && <p style={{ color: 'red' }}>{error}</p>}

      {!loading && !error && (
        <>
          <div>
            {conversations.map((conversation) => (
              <div key={conversation.id} style={{ border: '1px solid #ccc', padding: '10px', marginBottom: '10px' }}>
                <h2>{conversation.display_name}</h2>
                <p><strong>Cliente:</strong> {conversation.from_user.full_name}</p>
                <p><strong>Última interação:</strong> {new Date(conversation.last_interaction_at).toLocaleString()}</p>
                <p><strong>Status:</strong> {conversation.status}</p>
                <p><strong>Última mensagem:</strong> {conversation.last_message.content}</p>
                <p>
                  <strong>Produto:</strong> {conversation.tags.find(tag => tag.name === 'productTitle')?.value}
                </p>
                <img
                  src={conversation.tags.find(tag => tag.name === 'productImage')?.value.replace('{w}x{h}', '150x150')}
                  alt="Produto"
                  style={{ width: '150px', height: '150px' }}
                />
              </div>
            ))}
          </div>

          {/* Paginação */}
          <div style={{ marginTop: '20px' }}>
            <button onClick={handlePrevious} disabled={pagination.offset === 0}>
              Anterior
            </button>
            <button onClick={handleNext} disabled={pagination.offset + pagination.limit >= pagination.max_limit}>
              Próximo
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default Conversations;
