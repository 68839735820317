import React from 'react';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';

const PredictionsChart = ({ previsoes }) => {
  // Mapear previsões para o formato correto
  const dadosParaMostrar = previsoes.map((total, index) => ({
    date: `Dia ${index + 1}`,
    total,
  }));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={dadosParaMostrar}>
        <CartesianGrid stroke="#ccc" strokeDasharray="5 5" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey="total" stroke="#8884d8" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default PredictionsChart;
