import React, { useState } from 'react';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Brush,
  Area,
  defs,
  linearGradient,
  stop,
} from 'recharts';
import { format, parseISO } from 'date-fns';

// Função para agrupar dados por mês
const groupByMonth = (data) => {
  const grouped = {};
  data.forEach((item) => {
    const month = format(parseISO(item.date), 'yyyy-MM');
    if (!grouped[month]) {
      grouped[month] = { date: month, total: 0 };
    }
    grouped[month].total += item.total;
  });
  return Object.values(grouped);
};

// Função para calcular diferenças no dataset
const calculateDifferences = (data) => {
  return data.map((item, index) => {
    const prevTotal = index > 0 ? data[index - 1].total : 0;
    const difference = item.total - prevTotal;
    const percentage = prevTotal !== 0 ? (difference / prevTotal) * 100 : 0; // Calcula a porcentagem
    return { ...item, difference, percentage };
  });
};

const SalesChart = ({ data }) => {
  const [groupBy, setGroupBy] = useState('daily'); // 'daily' or 'monthly'

  // Transformar os dados com base na seleção de agrupamento
  const groupedData = groupBy === 'monthly' ? groupByMonth(data) : data;

  // Calcular as diferenças no dataset
  const transformedData = calculateDifferences(groupedData);

  return (
    <div style={{ color: '#ddd', fontFamily: 'Arial, sans-serif' }}>
      <div style={{ marginBottom: '20px' }}>
        <label style={{ marginRight: '10px' }}>Agrupar por:</label>
        <select value={groupBy} onChange={(e) => setGroupBy(e.target.value)}>
          <option value="daily">Diário</option>
          <option value="monthly">Mensal</option>
        </select>
      </div>

      <ResponsiveContainer width="100%" height={350}>
        <LineChart data={transformedData}>
          <defs>
            <linearGradient id="cyberpunkGradient" x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="5%" stopColor="#ff00ff" stopOpacity={0.8} />
              <stop offset="95%" stopColor="#00ffff" stopOpacity={0.8} />
            </linearGradient>
          </defs>
          <CartesianGrid stroke="#333" strokeDasharray="5 5" />
          <XAxis dataKey="date" tick={{ fill: '#ddd' }} />
          <YAxis tick={{ fill: '#ddd' }} />
          <Tooltip
            contentStyle={{ backgroundColor: '#222', borderColor: '#ff00ff' }}
            itemStyle={{ color: '#fff' }}
            formatter={(value, name, props) => {
              if (name === 'difference') {
                return [`${value > 0 ? '+' : ''}${value}`, 'Diferença'];
              } else if (name === 'percentage') {
                return [`${value.toFixed(2)}%`, 'Variação'];
              }
              return [value, name];
            }}
            labelFormatter={(label) => `Data: ${label}`}
          />
          <Line
            type="monotone"
            dataKey="total"
            stroke="url(#cyberpunkGradient)"
            strokeWidth={3}
            activeDot={{ r: 8 }}
          />
          <Area type="monotone" dataKey="total" fill="url(#cyberpunkGradient)" fillOpacity={0.3} />
          <Brush dataKey="date" height={30} stroke="#ff00ff" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default SalesChart;
