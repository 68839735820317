import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { DadosContext } from '../common/DadosContext';

export const SimuladorFrete = () => {
    const { tokens } = useContext(DadosContext);
    const [cepori, setCepori] = useState('02551060');
    const [cepsDes, setCepsDes] = useState([
        { estado: 'SP', cep: '01531001', porcentagem: 38.06 },
        { estado: 'PR', cep: '80410200', porcentagem: 8.65 },
        { estado: 'MG', cep: '30110008', porcentagem: 9.69 },
        { estado: 'RJ', cep: '22290120', porcentagem: 7.27 },
        { estado: 'RS', cep: '95689001', porcentagem: 6.23 },
        { estado: 'PE', cep: '50010000', porcentagem: 5.54 },
        { estado: 'SC', cep: '88010000', porcentagem: 5.54 },
    ]);
    const [pesoReal, setPesoReal] = useState('15');
    const [dimensoes, setDimensoes] = useState({ largura: 50, altura: 40, comprimento: 60 });
    const [vldeclarado, setVldeclarado] = useState('500');
    const [cnpj, setCnpj] = useState('96280192000132');
    const [error, setError] = useState('');
    const [results, setResults] = useState([]);
    const [mediaFrete, setMediaFrete] = useState(null);

    const token = tokens.find(token => token.id === 'jadlog')?.token;
    if (!token) {
        setError('Token não encontrado');
        return;
    }

    const calcularPesoCubado = ({ largura, altura, comprimento }) => {
        return (largura * altura * comprimento) / 6000;
    };

    const handleFinalSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setResults([]);
        setMediaFrete(null);

        const pesoCubado = calcularPesoCubado(dimensoes);
        const peso = Math.max(parseFloat(pesoReal), pesoCubado).toFixed(2);

        try {
            const formattedResults = [];
            for (const { estado, cep, porcentagem } of cepsDes) {
                try {
                    const response = await axios.post('https://nerp.com.br/jadlog/simulador/frete', {
                        token,
                        cepori,
                        cepdes: cep,
                        peso,
                        vldeclarado,
                        cnpj
                    });

                    if (response.data.frete && response.data.frete[0].erro) {
                        formattedResults.push({ estado, cep, porcentagem, error: response.data.frete[0].erro.descricao });
                    } else {
                        const { vltotal, prazo } = response.data.frete[0];
                        formattedResults.push({ estado, cep, porcentagem, vltotal, prazo });
                    }
                } catch (err) {
                    formattedResults.push({ estado, cep, porcentagem, error: err.response ? err.response.data : err.message || 'Erro ao conectar com o servidor' });
                }
            }
            setResults(formattedResults);
            calcularMediaFrete(formattedResults);
        } catch (err) {
            setError(err.message || 'Erro ao conectar com o servidor');
        }
    };

    const calcularMediaFrete = (results) => {
        const totalPesos = results.reduce((acc, cur) => acc + cur.porcentagem, 0);
        const media = results.reduce((acc, cur) => acc + (cur.vltotal * cur.porcentagem), 0) / totalPesos;
        setMediaFrete(media);
    };

    return (
        <div className="max-w-4xl mx-auto px-4 sm:px-6 py-24 min-h-screen">
            <div className="bg-white shadow-md rounded-lg p-8">
                <h2 className="text-2xl font-semibold text-gray-800 mb-6">Criação de Cotação de Frete</h2>
                {error && <p className="text-red-500 mb-4">{error}</p>}
                <form onSubmit={handleFinalSubmit} className="space-y-4">
                    <div>
                        <label className="block text-sm font-medium text-gray-700">CEP Origem:</label>
                        <input 
                            type="text" 
                            value={cepori} 
                            onChange={(e) => setCepori(e.target.value)} 
                            required 
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">CEPs Destino (estado:cep:porcentagem):</label>
                        <input 
                            type="text" 
                            value={cepsDes.map(({ estado, cep, porcentagem }) => `${estado}:${cep}:${porcentagem}`).join(', ')} 
                            onChange={(e) => setCepsDes(e.target.value.split(',').map(item => {
                                const [estado, cep, porcentagem] = item.split(':').map(str => str.trim());
                                return { estado, cep, porcentagem: parseFloat(porcentagem) };
                            }))} 
                            required 
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Peso Real (KG):</label>
                        <input 
                            type="number" 
                            step="0.01" 
                            value={pesoReal} 
                            onChange={(e) => setPesoReal(e.target.value)} 
                            required 
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Dimensões (cm):</label>
                        <div className="grid grid-cols-3 gap-4">
                            <input 
                                type="number" 
                                step="0.01" 
                                placeholder="Largura" 
                                value={dimensoes.largura} 
                                onChange={(e) => setDimensoes({ ...dimensoes, largura: e.target.value })} 
                                required 
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                            />
                            <input 
                                type="number" 
                                step="0.01" 
                                placeholder="Altura" 
                                value={dimensoes.altura} 
                                onChange={(e) => setDimensoes({ ...dimensoes, altura: e.target.value })} 
                                required 
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                            />
                            <input 
                                type="number" 
                                step="0.01" 
                                placeholder="Comprimento" 
                                value={dimensoes.comprimento} 
                                onChange={(e) => setDimensoes({ ...dimensoes, comprimento: e.target.value })} 
                                required 
                                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                            />
                        </div>
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">Valor Declarado:</label>
                        <input 
                            type="number" 
                            step="0.01" 
                            value={vldeclarado} 
                            onChange={(e) => setVldeclarado(e.target.value)} 
                            required 
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                        />
                    </div>
                    <div>
                        <label className="block text-sm font-medium text-gray-700">CNPJ:</label>
                        <input 
                            type="text" 
                            value={cnpj} 
                            onChange={(e) => setCnpj(e.target.value)} 
                            required 
                            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm" 
                        />
                    </div>
                    <button 
                        type="submit" 
                        className="w-full bg-indigo-600 text-white py-2 px-4 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm"
                    >
                        Calcular Frete
                    </button>
                </form>
                {results.length > 0 && (
                    <div className="mt-6">
                        <h3 className="text-lg font-medium text-gray-900 mb-4">Resultados:</h3>
                        <ul className="space-y-4">
                            {results.map((result, index) => (
                                <li key={index} className="bg-gray-50 p-4 rounded-lg shadow">
                                    <p><strong>Estado:</strong> {result.estado}</p>
                                    <p><strong>CEP:</strong> {result.cep}</p>
                                    <p><strong>Porcentagem:</strong> {result.porcentagem}%</p>
                                    {result.error ? (
                                        <p className="text-red-500"><strong>Erro:</strong> {result.error}</p>
                                    ) : (
                                        <>
                                            <p><strong>Valor Total:</strong> R$ {result.vltotal.toFixed(2)}</p>
                                            <p><strong>Prazo:</strong> {result.prazo} dias</p>
                                        </>
                                    )}
                                </li>
                            ))}
                        </ul>
                        {mediaFrete && (
                            <div className="mt-6">
                                <h4 className="text-xl font-bold text-gray-900">Média de Frete:</h4>
                                <p className="text-lg text-gray-700">R$ {mediaFrete.toFixed(2)}</p>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};
