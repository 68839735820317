import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import MercadoLivreCallback from './MercadoAuthComponent';
import { useAuth } from '../../common/AuthContext';

const MlAuthorizationCallback = () => {
  const { setCode } = useAuth();
  const [loading, setLoading] = useState(true);
  const [code, setCod] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthorizationCallback = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const authorization = urlParams.get('code');

        if (!authorization) {
          console.error('Authorization code is missing');
          return;
        }

        setCode(authorization);
        setCod(authorization);
        console.log('Authorization Code:', authorization);

        setLoading(false);
      } catch (error) {
        console.error('Error handling authorization callback:', error);
      }
    };

    handleAuthorizationCallback();
  }, [setCode, navigate]);

  return (
    <div>
      {loading ? (
        <div>
          <p>Processing the Authorization Server response...</p>
        </div>
      ) : (
        <div>
          <p>Authorization process completed!</p>
          <MercadoLivreCallback code={code} />
        </div>
      )}
    </div>
  );
};

export default MlAuthorizationCallback;
