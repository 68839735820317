import { useContext, useState } from "react";
import { Navigate } from "react-router-dom";
import { AuthGoogleContext } from "./LoginContext";

export const Login = ({ children }) => {
  const { signInGoogle, signInWithEmail, signed } = useContext(AuthGoogleContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  function handleLoginFromGoogle() {
    signInGoogle();
  }

  function handleLoginWithEmailAndPassword(e) {
    e.preventDefault();
    signInWithEmail(email, password);
  }

  if (!signed) {
    return (
      <div className="flex text-center items-center justify-center min-h-screen">
        <main className="mx-auto px-8 py-8">
          <div className="flex justify-center items-center">
            <div className="w-full shadow-md bg-gradient-to-r from-gray-200 rounded-md p-8">
              <form onSubmit={handleLoginWithEmailAndPassword} className="mt-2">
                <div className="mb-4">
                  <label htmlFor="email" className="text-black font-bold block mb-2">Email:</label>
                  <input
                    type="email"
                    id="email"
                    placeholder="Digite seu email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="max-w-xl appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                    required
                  />
                </div>
                <div className="mb-6">
                  <label htmlFor="password" className="text-black font-bold block mb-2">Senha:</label>
                  <input
                    type="password"
                    id="password"
                    placeholder="Digite sua senha"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    className="max-w-xl appearance-none border border-gray-400 rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:border-blue-500"
                    required
                  />
                </div>
                <div className="gap-8 flex flex-col items-center">
                  <button type="submit" className="w-1/2 max-w-xl bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                    Entrar
                  </button>
                  {/* <button onClick={handleLoginFromGoogle} className="w-xl bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded ">
                    <div className=" flex items-center">
                      <FaGoogle className="mr-2" />
                      Login Google
                    </div>
                  </button>
                  <button className="w-1/2 max-w-xs bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded">
                    Registrar
                  </button> */}
                </div>
              </form>
            </div>
          </div>
        </main>
      </div>
    );
  } else {
    return <Navigate to="/integracao" />;
  }
};
