import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import { DadosContext } from '../common/DadosContext';

export const Concorrente = () => {
    const { tokens } = useContext(DadosContext);
    const [userData, setUserData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const fetchUserData = async () => {
            const token = tokens.find(token => token.id === 'mercado_livre')?.token;
            
            setLoading(true);
            setError(null);
          
            try {
              const response = await axios.post('https://nerp.com.br/mercado-livre/user-info', {
                accessToken: token,
              });
          
              console.log(response);
              setUserData(response.data);
            } catch (error) {
              console.error('Error fetching user information:', error);
              setError('Failed to fetch user information');
            } finally {
              setLoading(false);
            }
        };

        fetchUserData();
    }, [tokens]);

    const renderUserInfo = () => (
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
            <h2 className="text-xl font-bold mb-4">User Information</h2>
            <p><strong>ID:</strong> {userData.id}</p>
            <p><strong>Nickname:</strong> {userData.nickname}</p>
            <p><strong>Registration Date:</strong> {new Date(userData.registration_date).toLocaleDateString()}</p>
            <p><strong>First Name:</strong> {userData.first_name}</p>
            <p><strong>Last Name:</strong> {userData.last_name}</p>
            <p><strong>Email:</strong> {userData.email}</p>
            <p><strong>Identification:</strong> {userData.identification.type} - {userData.identification.number}</p>
        </div>
    );

    const renderAddressInfo = () => (
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
            <h2 className="text-xl font-bold mb-4">Address</h2>
            <p><strong>Address:</strong> {userData.address.address}</p>
            <p><strong>City:</strong> {userData.address.city}</p>
            <p><strong>State:</strong> {userData.address.state}</p>
            <p><strong>Zip Code:</strong> {userData.address.zip_code}</p>
        </div>
    );

    const renderPhoneInfo = () => (
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
            <h2 className="text-xl font-bold mb-4">Phone Information</h2>
            <p><strong>Primary Phone:</strong> ({userData.phone.area_code}) {userData.phone.number}</p>
            <p><strong>Verified:</strong> {userData.phone.verified ? 'Yes' : 'No'}</p>
            <p><strong>Alternative Phone:</strong> ({userData.alternative_phone.area_code}) {userData.alternative_phone.number}</p>
        </div>
    );

    const renderSellerReputation = () => (
        <div className="bg-white shadow-md rounded-lg p-6 mb-6">
            <h2 className="text-xl font-bold mb-4">Seller Reputation</h2>
            <p><strong>Level:</strong> {userData.seller_reputation.level_id}</p>
            <p><strong>Transactions Completed:</strong> {userData.seller_reputation.transactions.completed}</p>
            <p><strong>Transactions Canceled:</strong> {userData.seller_reputation.transactions.canceled}</p>
            <p><strong>Ratings:</strong> Positive - {userData.seller_reputation.transactions.ratings.positive}, Negative - {userData.seller_reputation.transactions.ratings.negative}, Neutral - {userData.seller_reputation.transactions.ratings.neutral}</p>
            <p><strong>Sales Metrics:</strong></p>
            <ul>
                <li>Completed: {userData.seller_reputation.metrics.sales.completed}</li>
                <li>Claims Rate: {userData.seller_reputation.metrics.claims.rate}</li>
                <li>Delayed Handling Time Rate: {userData.seller_reputation.metrics.delayed_handling_time.rate}</li>
                <li>Cancellations Rate: {userData.seller_reputation.metrics.cancellations.rate}</li>
            </ul>
        </div>
    );

    return (
        <div className="items-center justify-center min-h-screen max-w-6xl mx-auto px-5 sm:px-6 py-24">
            {loading && <div>Loading...</div>}
            {error && <div className="text-red-500">{error}</div>}

            {userData && (
                <div>
                    <h1 className="text-2xl font-bold mb-4">Welcome, {userData.nickname}!</h1>
                    <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                        {renderUserInfo()}
                        {renderSellerReputation()}
                        {renderAddressInfo()}
                        {renderPhoneInfo()}
                        
                    </div>
                </div>
            )}
        </div>
    );
};
