import React, { useContext, useEffect, useState } from 'react';
import { DadosContext } from '../common/DadosContext';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../components/Firebase';

const ProdutosList = () => {
  const { user } = useContext(DadosContext);
  const [produtos, setProdutos] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (!user) return;

    const fetchProdutos = async () => {
      try {
        setLoading(true);
        setError(null);

        const produtosRef = collection(db, `users/${user.uid}/magaluprodutos`);
        const querySnapshot = await getDocs(produtosRef);
        const produtosList = querySnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setProdutos(produtosList);
      } catch (err) {
        console.error('Erro ao buscar produtos:', err);
        setError('Erro ao buscar produtos');
      } finally {
        setLoading(false);
      }
    };

    fetchProdutos();
  }, [user]);

  if (loading) {
    return <p>Carregando produtos...</p>;
  }

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  return (
    <div className="container mx-auto px-4 py-8">
      <h1 className="text-2xl font-bold mb-4">Lista de Produtos</h1>
      {produtos.length > 0 ? (
        <table className="min-w-full table-auto">
          <thead>
            <tr className="bg-gray-200">
              <th className="px-4 py-2 text-left">ID</th>
              <th className="px-4 py-2 text-left">Nome</th>
              <th className="px-4 py-2 text-left">Código</th>
              <th className="px-4 py-2 text-left">Marca</th>
            </tr>
          </thead>
          <tbody>
            {produtos.map((produto) => (
              <tr key={produto.id} className="border-b">
                <td className="px-4 py-2">{produto.id}</td>
                <td className="px-4 py-2">{produto.produtos?.title?.pt_BR || 'Sem título'}</td>
                <td className="px-4 py-2">{produto.produtos?.code || 'Sem código'}</td>
                <td className="px-4 py-2">{produto.produtos?.brand || 'Sem marca'}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>Nenhum produto encontrado.</p>
      )}
    </div>
  );
};

export default ProdutosList;
