
import { AuthProvider } from "./common/AuthContext";
import { AuthGoogleProvider } from "./common/LoginContext";
import { AppRoutes } from "./routes/routes";

export const App = () => {
  return (
    <AuthGoogleProvider>
      <AuthProvider>        
          <AppRoutes />
      </AuthProvider>
    </AuthGoogleProvider >
  );
};