import React, { useState } from 'react';
import axios from 'axios';

const ProdutoAmericanas = () => {
  const [sku, setSku] = useState('');
  const [produto, setProduto] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [newPrice, setNewPrice] = useState('');
  const [newPromotionalPrice, setNewPromotionalPrice] = useState('');

  const email = "laiza@conthey.com.br";
  const api = "SS3Q-EwyfcZz5Qcc1nbk";
  const accountManagerKey = "T4hEXIhnHW"; // Substitua com o token correto

  const handleSearch = async () => {
    setLoading(true);
    setError(null);
    setProduto(null);

    try {
      const response = await axios.get(`https://api.skyhub.com.br/products/${sku}`, {
        headers: {
          'X-User-Email': email,
          'X-Api-Key': api,
          'X-Accountmanager-Key': accountManagerKey,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      setProduto(response.data);
      console.log(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdatePrice = async () => {
    setLoading(true);
    setError(null);

    try {
      const response = await axios.put(`https://api.skyhub.com.br/products/${sku}`, {
        product: {
          price: parseFloat(newPrice),
          promotional_price: parseFloat(newPromotionalPrice)
        }
      }, {
        headers: {
          'X-User-Email': email,
          'X-Api-Key': api,
          'X-Accountmanager-Key': accountManagerKey,
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      });
      setProduto(response.data);
      console.log(response.data);
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="max-w-4xl mx-auto px-4 sm:px-6 py-24 min-h-screen">
      <h1 className="text-2xl font-bold mb-4">Pesquisar e Atualizar Produto</h1>
      <div className="mb-4">
        <input
          type="text"
          className="border p-2 rounded w-full"
          placeholder="Digite o SKU do produto"
          value={sku}
          onChange={(e) => setSku(e.target.value)}
        />
        <button
          onClick={handleSearch}
          className="bg-blue-500 text-white p-2 rounded mt-2 w-full"
        >
          Pesquisar
        </button>
      </div>
      {loading && <div className="text-center">Loading...</div>}
      {error && <div className="text-center text-red-500">Error: {error.message}</div>}
      {produto && (
        <div>
          <h2 className="text-xl font-bold mb-2">Detalhes do Produto</h2>
          <div className="mb-2">
            <span className="font-semibold">SKU:</span> {produto.sku}
          </div>
          <div className="mb-2">
            <span className="font-semibold">Preço:</span> ${produto.price}
          </div>
          <div className="mb-2">
            <span className="font-semibold">Preço Promocional:</span> ${produto.promotional_price}
          </div>
          <div className="mb-4">
            <h3 className="text-lg font-semibold mb-2">Atualizar Preços</h3>
            <input
              type="number"
              className="border p-2 rounded w-full mb-2"
              placeholder="Novo Preço"
              value={newPrice}
              onChange={(e) => setNewPrice(e.target.value)}
            />
            <input
              type="number"
              className="border p-2 rounded w-full mb-2"
              placeholder="Novo Preço Promocional"
              value={newPromotionalPrice}
              onChange={(e) => setNewPromotionalPrice(e.target.value)}
            />
            <button
              onClick={handleUpdatePrice}
              className="bg-green-500 text-white p-2 rounded w-full"
            >
              Atualizar Preços
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProdutoAmericanas;
