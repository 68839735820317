import * as tf from '@tensorflow/tfjs';
import dayjs from 'dayjs';

export const agruparVendasPorData = (data) => {
  const agrupado = data.reduce((acc, order) => {
    const dataCompra = dayjs(order.purchasedAt).format('YYYY-MM-DD');
    if (!acc[dataCompra]) acc[dataCompra] = 0;
    acc[dataCompra] += order.total;
    return acc;
  }, {});

  return Object.keys(agrupado)
    .sort()
    .map((date) => ({ date, total: agrupado[date] }));
};

export const detectarAnomalias = (dados, limiar = 1.5) => {
  if (dados.length === 0) return [];
  const valores = dados.map((item) => item.total);
  const media = valores.reduce((a, b) => a + b, 0) / valores.length;
  const desvioPadrao = Math.sqrt(
    valores.reduce((sum, val) => sum + Math.pow(val - media, 2), 0) / valores.length
  );

  return dados.map((item) => ({
    ...item,
    isAnomalia: Math.abs(item.total - media) > limiar * desvioPadrao,
  }));
};

export const previsaoVendasTensorFlow = async (historicoVendas, setPrevisoes) => {
    // Limpar previsões existentes
    setPrevisoes([]);
  
    // Liberar variáveis existentes para evitar conflitos
    tf.disposeVariables();
  
    // Filtrar dados para excluir a data de hoje
    const historicoFiltrado = historicoVendas.filter((item) => {
      const itemDate = dayjs(item.date);
      return !itemDate.isSame(dayjs(), 'day'); // Exclui dados da data de hoje
    });
  
    if (historicoFiltrado.length <= 10) {
      console.error('Dados insuficientes para treinamento após filtrar a data de hoje.');
      return;
    }
  
    const valores = historicoFiltrado.map((item) => item.total);
    const windowSize = 10;
  
    const tensorX = [];
    const tensorY = [];
    for (let i = 0; i < valores.length - windowSize; i++) {
      tensorX.push(valores.slice(i, i + windowSize));
      tensorY.push(valores[i + windowSize]);
    }
  
    const xTrain = tf.tensor2d(tensorX, [tensorX.length, windowSize]);
    const yTrain = tf.tensor2d(tensorY, [tensorY.length, 1]);
  
    const model = tf.sequential();
    model.add(tf.layers.dense({ units: 128, activation: 'relu', inputShape: [windowSize] }));
    model.add(tf.layers.dense({ units: 64, activation: 'relu' }));
    model.add(tf.layers.dense({ units: 1 }));
    model.compile({ optimizer: 'adam', loss: 'meanSquaredError' });
  
    try {
      // Treinamento do modelo
      await model.fit(xTrain, yTrain, { epochs: 150, batchSize: 32 });
  
      // Previsão futura
      const previsoesFuturas = [];
      let ultimaJanela = valores.slice(-windowSize);
  
      for (let i = 0; i < 30; i++) {
        const tensorInput = tf.tensor2d([ultimaJanela], [1, windowSize]);
        const proximaPrevisao = model.predict(tensorInput).arraySync()[0][0];
        previsoesFuturas.push(proximaPrevisao);
        ultimaJanela = [...ultimaJanela.slice(1), proximaPrevisao];
        tensorInput.dispose(); // Libera tensor de entrada após uso
      }
  
      setPrevisoes(previsoesFuturas);
    } catch (error) {
      console.error('Erro no treinamento ou previsão do modelo:', error);
    } finally {
      // Libera tensores e modelo após o uso
      xTrain.dispose();
      yTrain.dispose();
      model.dispose();
      tf.disposeVariables();
    }
  };
