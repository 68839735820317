import React, { useEffect, useState } from 'react';
import * as tf from '@tensorflow/tfjs';
import { Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';

const PotentialProductsAndAnomalies = ({ data }) => {
  const [processedProducts, setProcessedProducts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const analyzeProducts = async () => {
      setLoading(true);

      // Processar produtos e gerar previsões
      const processed = await Promise.all(
        data.map(async (product) => {
          const historicalData = product.history || [];
          const predictedSales = await predictSalesWithTensorFlow(historicalData);

          // Simular cenários de preço
          const scenarios = simulatePricingScenarios(predictedSales, product);

          return {
            ...product,
            predictedSales,
            scenarios,
            isAnomaly: product.total < 100 || product.quantidade < 5,
          };
        })
      );

      setProcessedProducts(processed);
      setLoading(false);
    };

    analyzeProducts();
  }, [data]);

  // Função para criar e treinar o modelo TensorFlow.js
  const predictSalesWithTensorFlow = async (historicalData) => {
    if (historicalData.length < 2) return []; // Garantir dados suficientes para treinamento

    // Preparar dados para o modelo
    const inputs = historicalData.map((d) => d.date);
    const labels = historicalData.map((d) => d.total);

    // Normalizar os dados (opcional, depende da escala)
    const inputTensor = tf.tensor2d(inputs.map((d, i) => [i]));
    const labelTensor = tf.tensor2d(labels, [labels.length, 1]);

    // Criar o modelo
    const model = tf.sequential();
    model.add(tf.layers.dense({ inputShape: [1], units: 10, activation: 'relu' }));
    model.add(tf.layers.dense({ units: 1 }));

    // Compilar o modelo
    model.compile({ optimizer: 'sgd', loss: 'meanSquaredError' });

    // Treinar o modelo
    await model.fit(inputTensor, labelTensor, { epochs: 50 });

    // Fazer previsões
    const futureInputs = tf.tensor2d([...Array(10).keys()].map((i) => [inputs.length + i]));
    const predictions = model.predict(futureInputs).dataSync();

    return predictions.map((pred, i) => ({
      date: `Dia ${inputs.length + i}`,
      total: pred,
    }));
  };

  const simulatePricingScenarios = (predictedSales, product) => {
    const scenarios = [];

    [0.9, 1, 1.1, 1.2].forEach((priceMultiplier) => {
      const adjustedPrice = product.price * priceMultiplier;
      const adjustedSales = predictedSales.map((sales) => ({
        ...sales,
        total: sales.total * priceMultiplier,
      }));

      const expectedRevenue = adjustedSales.reduce((acc, sale) => acc + sale.total, 0);
      const margin = adjustedPrice - (product.cost || 0);

      scenarios.push({
        price: adjustedPrice,
        expectedRevenue: expectedRevenue,
        margin: margin,
        marginPercent: ((margin / adjustedPrice) * 100),
      });
    });

    return scenarios;
  };

  return (
    <div className="bg-gray-800 p-6 rounded-lg shadow-lg text-white">
      <h2 className="text-2xl font-bold mb-4">Produtos com Potencial e Cenários de Preço</h2>
      {loading ? (
        <p>Carregando dados...</p>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Produto</TableCell>
              <TableCell>Preço Atual (R$)</TableCell>
              <TableCell>Cenários de Preço</TableCell>
              <TableCell>Anomalia</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {processedProducts.map((product, index) => (
              <TableRow key={index}>
                <TableCell>{product.nome}</TableCell>
                <TableCell>{product.price}</TableCell>
                <TableCell>
                  {product.scenarios.map((scenario, idx) => (
                    <div key={idx}>
                      <p>
                        Preço: R$ {scenario.price} - Receita Esperada: R$ {scenario.expectedRevenue} - Margem: R$
                        {scenario.margin} ({scenario.marginPercent}%)
                      </p>
                    </div>
                  ))}
                </TableCell>
                <TableCell>{product.isAnomaly ? 'Sim' : 'Não'}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      )}
    </div>
  );
};

export default PotentialProductsAndAnomalies;
