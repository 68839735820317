import React, { useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../common/AuthContext';

const MercadoLivreCallback = ({ code }) => {
  const { setAccessML , setRefreshML } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthorizationCallback = async () => {
      if (!code) {
        console.error('Authorization code is missing');
        return;
      }

      const payload = new URLSearchParams({
        grant_type: 'authorization_code',
        client_id: '3019996311707366',
        client_secret: 'Mv8yIaoMPf111BOhAOgY3kXN96KV16vG',
        code,
        redirect_uri: 'https://nerp.com.br/mlcallback'
      }).toString();

      try {
        const response = await axios.post('https://api.mercadolibre.com/oauth/token', payload, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
            'Accept': 'application/json'
          }
        });
        console.log('Response:', response.data);

        // Salvar os tokens no contexto
        setAccessML( response.data.access_token);
        setRefreshML(response.data.refresh_token);
        
        navigate('/integracao');
      } catch (error) {
        console.error('Error handling MercadoLivre authorization:', error.response ? error.response.data : error.message);
      }
    };

    handleAuthorizationCallback();
  }, [code, navigate, setAccessML, setRefreshML]);

  return null;
};

export default MercadoLivreCallback;
