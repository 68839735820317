import { createContext, useContext, useState, useEffect } from 'react';
import moment from 'moment';
import axios from 'axios';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [authorizationCode, setAuthorizationCode] = useState('');
  const [refreshCode, setrefreshCode] = useState('');
  const [accessCode, setaccessCode] = useState(''); 
  const [refreshCodeML, setrefreshCodeML] = useState(''); 
  const [accessCodeML, setaccessCodeML] = useState('');

  const [refreshCodeMagalu, setrefreshCodeMagalu] = useState(''); 
  const [accessCodeMagalu, setaccessCodeMagalu] = useState('');
  const [refreshCodeMagaluMSG, setrefreshCodeMagaluMSG] = useState(''); 
  const [accessCodeMagaluMSG, setaccessCodeMagaluMSG] = useState('');

  const [token, setTokens] = useState([]);  

  const [filtroDatas, setFiltroDatas] = useState({
    startDate: moment().subtract(7, 'days').startOf('day').toDate(),
    endDate: moment().endOf('day').toDate()
  });

 
  const updateFiltroDatas = (startDate, endDate) => {
    setFiltroDatas({
      startDate: moment(startDate, 'YYYY-MM-DD').startOf('day').toDate(),
      endDate: moment(endDate, 'YYYY-MM-DD').endOf('day').toDate(),
    });
  };
  

  const setCode = (code) => {
    setAuthorizationCode(code);
  };

  const setAccess = (code) => {
    setaccessCode(code);
  };

  const setRefresh = (code) => {
    setrefreshCode(code);
  };

  const setAccessML = (code) => {
    setaccessCodeML(code);
  };

  const setRefreshML = (code) => {
    setrefreshCodeML(code);
  };

  
  return (
    <AuthContext.Provider
      value={{
        authorizationCode,
        setCode,
        setAccess,
        setRefresh,
        setAccessML,
        setRefreshML,
        accessCode,
        accessCodeML,
        refreshCodeML,
        setaccessCodeMagalu,
        accessCodeMagalu,
        setrefreshCodeMagalu,
        refreshCodeMagalu,
        setaccessCodeMagaluMSG,
        accessCodeMagaluMSG,
        setrefreshCodeMagaluMSG,
        refreshCodeMagaluMSG,
        

        refreshCode,

      }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
