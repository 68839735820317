import React, { useContext, useState } from 'react';
import axios from 'axios';
import ListaLogistica from './ListaJadlog';
import { DadosContext } from '../../common/DadosContext';

const TrackingForm = () => {
  const { createLogJadlog, tokens } = useContext(DadosContext);
  const [notaFiscal, setNotaFiscal] = useState('');
  const [cnpjRemetente, setCnpjRemetente] = useState('');
  const [codigo, setCodigo] = useState('');
  const [result, setResult] = useState(null);
  const [error, setError] = useState(null);

  const handleSubmitNF = async (e) => {
    e.preventDefault();
    setError(null);
    setResult(null);

    const token = tokens.find(token => token.id === 'jadlog')?.token;
    if (!token) {
      setError('Token não encontrado');
      return;
    }

    try {
      const response = await axios.post('/jadlog/tracking/consultar', {
        token,
        notaFiscal,
        cnpjRemetente
      });
      if (response.data.consulta && response.data.consulta[0].erro) {
        setError(response.data.consulta[0].erro.descricao);
      } else {
        setResult(response.data.consulta[0]);
        await createLogJadlog(response.data.consulta[0]);
      }
    } catch (err) {
      setError(err.response ? err.response.data : err.message || 'Erro ao conectar com o servidor');
    }
  };

  const handleSubmitCodigo = async (e) => {
    e.preventDefault();
    setError(null);
    setResult(null);

    const token = tokens.find(token => token.id === 'jadlog')?.token;
    if (!token) {
      setError('Token não encontrado');
      return;
    }

    try {
      const response = await axios.post('http://localhost:5000/jadlog/tracking/consultar/codigo', {
        token,
        codigo
      });
      if (response.data.consulta && response.data.consulta[0].erro) {
        setError(response.data.consulta[0].erro.descricao);
      } else {
        setResult(response.data.consulta[0]);
        await createLogJadlog(response.data.consulta[0]);
      }
    } catch (err) {
      setError(err.response ? err.response.data : err.message || 'Erro ao conectar com o servidor');
    }
  };

  return (
    <div className="max-w-6xl mx-auto px-4 sm:px-6 min-h-screen py-24">
      <h1 className="text-2xl font-bold mb-4">Consulta de Tracking Jadlog</h1>
      {/* <form onSubmit={handleSubmitNF} className="mb-6">
        <div className="mb-4">
          <label htmlFor="notaFiscal" className="block text-gray-700 text-sm font-bold mb-2">Nota Fiscal:</label>
          <input
            type="text"
            id="notaFiscal"
            value={notaFiscal}
            onChange={(e) => setNotaFiscal(e.target.value)}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <div className="mb-4">
          <label htmlFor="cnpjRemetente" className="block text-gray-700 text-sm font-bold mb-2">CNPJ do Remetente:</label>
          <input
            type="text"
            id="cnpjRemetente"
            value={cnpjRemetente}
            onChange={(e) => setCnpjRemetente(e.target.value)}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Consultar por Nota Fiscal
        </button>
      </form> */}
      <form onSubmit={handleSubmitCodigo} className="mb-6">
        <div className="mb-4">
          <label htmlFor="codigo" className="block text-gray-700 text-sm font-bold mb-2">Código:</label>
          <input
            type="text"
            id="codigo"
            value={codigo}
            onChange={(e) => setCodigo(e.target.value)}
            required
            className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
          />
        </div>
        <button type="submit" className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline">
          Consultar por Código
        </button>
      </form>
      {error && <div className="text-red-500 mb-4">Erro: {error}</div>}
      {result && <TrackingResult result={result} />}
      <ListaLogistica />
    </div>
  );
};

const TrackingResult = ({ result }) => {
  const tracking = result.tracking || {};
  const eventos = tracking.eventos || [];
  const volumes = tracking.volumes || [];
  const [showEventos, setShowEventos] = useState(false);

  const toggleEventos = () => {
    setShowEventos(!showEventos);
  };

  return (
    <div className="p-6 bg-white shadow-md rounded-lg">
      <h2 className="text-2xl font-bold mb-6">Resultado da Consulta</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        <div className="mb-4">
          <strong>Shipment ID:</strong> {tracking.shipmentId}
        </div>
        <div className="mb-4">
          <strong>Chave DANFE:</strong> {tracking.dacte}
        </div>
        <div className="mb-4">
          <strong>Data de Emissão:</strong> {tracking.dtEmissao}
        </div>
        <div className="mb-4">
          <strong>Status:</strong> {tracking.status}
        </div>
        <div className="mb-4">
          <strong>Valor:</strong> R$ {tracking.valor}
        </div>
        <div className="mb-4">
          <strong>Peso:</strong> {tracking.peso} kg
        </div>
        <div className="mb-4">
          <strong>Recebedor:</strong> {tracking.recebedor?.nome}
        </div>
        <div className="mb-4">
          <strong>Data de Recebimento:</strong> {tracking.recebedor?.data}
        </div>
        <div className="mb-4">
          <strong>Previsão de Entrega:</strong> {result.previsaoEntrega}
        </div>
      </div>
      <div className="mt-6">
        <h3 className="text-xl font-semibold mb-4">Eventos</h3>
        <button className="text-blue-500 mb-4" onClick={toggleEventos}>
          {showEventos ? 'Mostrar Menos' : 'Ver Mais'}
        </button>
        {showEventos && (
          <ul className="space-y-4">
            {eventos.map((evento, index) => (
              <li key={index} className="p-4 border rounded-lg bg-gray-100">
                <div><strong>Data:</strong> {evento.data}</div>
                <div><strong>Status:</strong> {evento.status}</div>
                <div><strong>Unidade:</strong> {evento.unidade}</div>
              </li>
            ))}
          </ul>
        )}
      </div>
      <div className="mt-6">
        <h3 className="text-xl font-semibold mb-4">Volumes</h3>
        <ul className="space-y-4">
          {volumes.map((volume, index) => (
            <li key={index} className="p-4 border rounded-lg bg-gray-100">
              <div><strong>Peso:</strong> {volume.peso} kg</div>
              <div><strong>Altura:</strong> {volume.altura} cm</div>
              <div><strong>Largura:</strong> {volume.largura} cm</div>
              <div><strong>Comprimento:</strong> {volume.comprimento} cm</div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default TrackingForm;
