import React, { useContext, useState } from 'react';
import { xml2js } from 'xml-js';
import axios from 'axios';
import { DadosContext } from '../../common/DadosContext';

export const CriarColetaJadlog = () => {
  const { tokens } = useContext(DadosContext);
  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const [filteredContent, setFilteredContent] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const token = tokens.find(token => token.id === 'jadlog')?.token;

  if (!token) {
    setError('Token não encontrado');
    return null;
  }

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
  };

  const handleContentChange = (event, key) => {
    const { value } = event.target;
    setFilteredContent({
      ...filteredContent,
      [key]: value
    });
  };

  const handleNestedContentChange = (event, parentKey, key) => {
    const { value } = event.target;
    setFilteredContent({
      ...filteredContent,
      [parentKey]: {
        ...filteredContent[parentKey],
        [key]: value
      }
    });
  };
  

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!file) {
      alert('Por favor, selecione um arquivo');
      return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
      
      
      const xml = e.target.result;
      try {
        const result = xml2js(xml, { compact: true, spaces: 4 });
        const formatCEP = (cep) => {
          return cep.replace(/\D/g, ''); // Remove qualquer caractere que não seja um número
        };
        const rawCep = result.nfeProc.NFe.infNFe.dest.enderDest.CEP._text; // Extrair CEP bruto
        const formattedCep = formatCEP(rawCep);

        const unidadeResponse = axios.post('https://nerp.com.br/jadlog/ponto-coleta', {
          token,
          CEP: formattedCep
        });
        const cdUnidadeOri = unidadeResponse.data.cdUnidadeOri;
        console.log(cdUnidadeOri)
        console.log(result)
        const filtered = {
          codCliente: '',
          conteudo: result.nfeProc.NFe.infNFe.det.prod.xProd._text,
          pedido: result.nfeProc.NFe.infNFe.ide.nNF._text,
          totPeso: parseFloat(result.nfeProc.NFe.infNFe.transp.vol.pesoL._text),
          totValor: parseFloat(result.nfeProc.NFe.infNFe.total.ICMSTot.vNF._text),
          obs: "emitir na modalidade mais barata com cubagem especial",
          modalidade: 3,
          contaCorrente: "013414",
          tpColeta: "K",
          tipoFrete: parseInt(result.nfeProc.NFe.infNFe.transp.modFrete._text),
          cdUnidadeOri: null,
          cdUnidadeDes: null,
          cdPickupOri: null,
          cdPickupDes: null,
          nrContrato: 0,
          servico: 0,
          shipmentId: null,
          vlColeta: null,
          rem: {
            nome: result.nfeProc.NFe.infNFe.dest.xNome._text,
            cnpjCpf: result.nfeProc.NFe.infNFe.dest.CPF._text,
            ie: result.nfeProc.NFe.infNFe.dest.IE ? result.nfeProc.NFe.infNFe.dest.IE._text : '',
            endereco: result.nfeProc.NFe.infNFe.dest.enderDest.xLgr._text,
            numero: result.nfeProc.NFe.infNFe.dest.enderDest.nro._text,
            compl: result.nfeProc.NFe.infNFe.dest.enderDest.xCpl ? result.nfeProc.NFe.infNFe.dest.enderDest.xCpl._text : '',
            bairro: result.nfeProc.NFe.infNFe.dest.enderDest.xBairro._text,
            cidade: result.nfeProc.NFe.infNFe.dest.enderDest.xMun._text,
            uf: result.nfeProc.NFe.infNFe.dest.enderDest.UF._text,
            cep: result.nfeProc.NFe.infNFe.dest.enderDest.CEP._text,
            fone: result.nfeProc.NFe.infNFe.dest.enderDest.fone ? result.nfeProc.NFe.infNFe.dest.enderDest.fone._text : '',
            cel: "", 
            email: result.nfeProc.NFe.infNFe.dest.email._text, 
            contato: result.nfeProc.NFe.infNFe.dest.xNome._text
          },
          des: {
            nome: result.nfeProc.NFe.infNFe.emit.xNome._text,
            cnpjCpf: result.nfeProc.NFe.infNFe.emit.CNPJ._text,
            ie: result.nfeProc.NFe.infNFe.emit.IE ? result.nfeProc.NFe.infNFe.emit.IE._text : '',
            endereco: result.nfeProc.NFe.infNFe.emit.enderEmit.xLgr._text,
            numero: result.nfeProc.NFe.infNFe.emit.enderEmit.nro._text,
            compl: result.nfeProc.NFe.infNFe.emit.enderEmit.xCpl ? result.nfeProc.NFe.infNFe.emit.enderEmit.xCpl._text : '',
            bairro: result.nfeProc.NFe.infNFe.emit.enderEmit.xBairro._text,
            cidade: result.nfeProc.NFe.infNFe.emit.enderEmit.xMun._text,
            uf: result.nfeProc.NFe.infNFe.emit.enderEmit.UF._text,
            cep: result.nfeProc.NFe.infNFe.emit.enderEmit.CEP._text,
            fone: result.nfeProc.NFe.infNFe.emit.enderEmit.fone ? result.nfeProc.NFe.infNFe.emit.enderEmit.fone._text : '',
            cel: "",
            email: "",
            contato: result.nfeProc.NFe.infNFe.emit.xNome._text
          },
          dfe: [
            {
              cfop: result.nfeProc.NFe.infNFe.det.prod.CFOP._text,
              danfeCte: result.nfeProc.protNFe.infProt.chNFe._text,
              nrDoc: result.nfeProc.NFe.infNFe.ide.nNF._text,
              serie: result.nfeProc.NFe.infNFe.ide.serie._text,
              tpDocumento: 2,
              valor: parseFloat(result.nfeProc.NFe.infNFe.total.ICMSTot.vNF._text)
            }
          ],
          volume: [
            {
              altura: 1,
              comprimento: 1,
              identificador: result.nfeProc.NFe.infNFe.det.prod.cProd._text,
              largura: 1,
              peso: parseFloat(result.nfeProc.NFe.infNFe.transp.vol.pesoL._text)
            }
          ]
        };

        setFilteredContent(filtered);
        
      } catch (error) {
        console.error('Erro ao converter XML:', error);
      }
    };

    reader.onerror = (error) => {
      console.error('Erro ao ler o arquivo:', error);
    };

    reader.readAsText(file);
  };
  const handleSend = async () => {
    if (!filteredContent || !token) {
      alert('Por favor, preencha todos os campos necessários');
      return;
    }

    setIsSubmitting(true);

    try {
      const response = await axios.post('https://nerp.com.br/jadlog/pedido/incluir', {
        token,
        pedidoData: filteredContent
      });
      console.log('Resposta do servidor:', response.data);
      alert('Pedido incluído com sucesso');
    } catch (error) {
      console.error('Erro ao incluir pedido:', error);
      alert('Erro ao incluir pedido');
    } finally {
      setIsSubmitting(false);
    }
  };

  const renderEditableContent = (content, parentKey = '') => {
    if (!content) return null;
    return (
      <div className="space-y-4">
        <div className="flex flex-wrap -mx-2">
          {Object.keys(content).map((key) => (
            Array.isArray(content[key]) ? (
              <div key={key} className="w-full mb-6">
                <h3 className="text-lg font-bold mb-2">{key.charAt(0).toUpperCase() + key.slice(1)}</h3>
                <div className="flex flex-wrap -mx-2">
                  {content[key].map((item, index) => (
                    <div key={index} className="sm:w-1/2 lg:w-1/3 px-2 mb-4">
                      <div className="p-4 border rounded-lg">
                        {renderEditableContent(item, `${parentKey ? `${parentKey}.` : ''}${key}[${index}]`)}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            ) : typeof content[key] === 'object' && (key === 'rem' || key === 'des' || key === 'dfe' || key === 'volume') ? (
              <div key={key} className="sm:w-1/2 lg:w-1/2 px-2 mb-4">
                <h3 className="text-lg font-bold mb-2">{key.charAt(0).toUpperCase() + key.slice(1)}</h3>
                <div className="p-4 border rounded-lg">
                  {renderEditableContent(content[key], `${parentKey ? `${parentKey}.` : ''}${key}`)}
                </div>
              </div>
            ) : typeof content[key] === 'object' ? (
              <div key={key} className="sm:w-1/2 lg:w-1/3 px-2 mb-4">
                <h3 className="text-lg font-bold mb-2">{key.charAt(0).toUpperCase() + key.slice(1)}</h3>
                <div className="p-4 border rounded-lg">
                  {renderEditableContent(content[key], `${parentKey ? `${parentKey}.` : ''}${key}`)}
                </div>
              </div>
            ) : (
              <div key={key} className="sm:w-1/2 lg:w-1/3 px-2 mb-4">
                <label className="block text-gray-700 text-sm font-bold mb-2" htmlFor={key}>
                  {key.charAt(0).toUpperCase() + key.slice(1)}
                </label>
                <input
                  type="text"
                  id={key}
                  name={key}
                  value={content[key]}
                  onChange={(event) => parentKey ? handleNestedContentChange(event, parentKey, key) : handleContentChange(event, key)}
                  className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline"
                />
              </div>
            )
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className='max-w-6xl mx-auto px-4 sm:px-6 min-h-screen py-24'>
      {error && <div className="bg-red-100 border border-red-400 text-red-700 px-4 py-3 rounded relative mb-4">{error}</div>}
      <form onSubmit={handleSubmit} className="bg-white p-6 rounded-lg shadow-lg mb-6">
        <h2 className="text-2xl font-bold mb-4">Upload XML</h2>
        <input 
          type="file" 
          accept=".xml" 
          onChange={handleFileChange} 
          className="mb-4 block w-full text-sm text-gray-500 file:mr-4 file:py-2 file:px-4 file:rounded-full file:border-0 file:text-sm file:font-semibold file:bg-blue-50 file:text-blue-700 hover:file:bg-blue-100"
        />
        <button 
          type="submit" 
          className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
        >
          Upload
        </button>
      </form>      

      {filteredContent && (
        <div className="mt-6 bg-white p-6 rounded-lg shadow-lg">
          <h3 className="text-xl font-bold mb-4">Informações Filtradas do XML:</h3>
          <div className="flex flex-wrap -mx-2">
            {renderEditableContent(filteredContent)}
          </div>
          <button
            onClick={handleSend}
            disabled={isSubmitting}
            className="w-full bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded mt-4"
          >
            {isSubmitting ? 'Enviando...' : 'Enviar Pedido'}
          </button>
        </div>
      )}
    </div>
  );
};
