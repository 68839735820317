import React, { useState, useEffect, useContext } from 'react';
import { collection, getDocs } from 'firebase/firestore';
import { db } from '../components/Firebase';
import { DadosContext } from '../common/DadosContext';
import * as XLSX from 'xlsx';

const CurvaABCTable = ({ products }) => {
  const { user, tokens } = useContext(DadosContext);
  const [productsWithNames, setProductsWithNames] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [modalData, setModalData] = useState(null);
  const [modalVisible, setModalVisible] = useState(false);

  useEffect(() => {
    if (!user || !products) return;

    const fetchProductNames = async () => {
      try {
        setLoading(true);
        setError(null);

        const productsRef = collection(db, `users/${user.uid}/magaluprodutos`);
        const querySnapshot = await getDocs(productsRef);

        const firebaseProducts = querySnapshot.docs.reduce((acc, doc) => {
          const data = doc.data();
          if (data.code && data.title?.pt_BR) {
            acc[data.code.trim().toUpperCase()] = data.title.pt_BR.trim();
          }
          return acc;
        }, {});

        const combinedProducts = products.map((product) => {
          const skuNormalized = product.sku?.trim().toUpperCase();
          const nomeEncontrado = firebaseProducts[skuNormalized] || 'Nome não encontrado';
          return { ...product, nome: nomeEncontrado };
        });

        // Cálculo da Curva ABC
        const totalVendas = combinedProducts.reduce((acc, prod) => acc + prod.total, 0);
        let acumulado = 0;

        const produtosABC = combinedProducts
          .sort((a, b) => b.total - a.total)
          .map((produto) => {
            const contribuicao = (produto.total / totalVendas) * 100;
            acumulado += contribuicao;

            return {
              ...produto,
              contribuicao: contribuicao.toFixed(2),
              acumulado: acumulado.toFixed(2),
              categoria: acumulado <= 80 ? 'A' : acumulado <= 95 ? 'B' : 'C',
            };
          });

        setProductsWithNames(produtosABC);
      } catch (err) {
        console.error('Erro ao buscar nomes dos produtos:', err);
        setError('Erro ao buscar nomes dos produtos.');
      } finally {
        setLoading(false);
      }
    };

    fetchProductNames();
  }, [user, products]);

  const fetchProductDetails = async (sku) => {
    const token = tokens.find((t) => t.id === 'magalu')?.token;

    if (!token) {
      setError('Token não encontrado');
      setLoading(false);
      return;
    }

    try {
      setLoading(true);
      const response = await fetch('https://nerp.com.br/magalu/sku', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ accessToken: token, code__in: sku }),
      });

      if (!response.ok) throw new Error('Erro ao buscar detalhes do produto');
      const data = await response.json();
      setModalData(data.results[0]);
      setModalVisible(true);
    } catch (err) {
      console.error(err);
      setError('Erro ao buscar detalhes do produto.');
    } finally {
      setLoading(false);
    }
  };

  const closeModal = () => {
    setModalVisible(false);
    setModalData(null);
  };

  const formatCurrency = (value) => {
    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(value);
  };

  return (
    <div className="fundo-card p-6 rounded-lg shadow-lg hover:shadow-xl transition duration-300 ease-in-out mb-8">
      <h2 className="text-2xl font-bold text-white mb-4">Curva ABC</h2>
      {loading && <p className="text-center text-gray-500">Carregando...</p>}
      {error && <p className="text-center text-red-500">{error}</p>}

      <div className="overflow-y-auto max-h-128">
        <table className="table-auto w-full text-white">
          <thead>
            <tr>
              <th className="px-4 py-2">SKU</th>
              <th className="px-4 py-2">Total (R$)</th>
              <th className="px-4 py-2">Pedidos</th>
              <th className="px-4 py-2">Preço Médio (R$)</th>
              <th className="px-4 py-2">% Contribuição</th>
              <th className="px-4 py-2">% Acumulado</th>
              <th className="px-4 py-2">Categoria</th>
            </tr>
          </thead>
          <tbody>
            {productsWithNames.map((produto, index) => (
              <tr key={index} className="border-b border-gray-600">
                <td
                  className="px-4 py-2 cursor-pointer text-blue-500 hover:underline"
                  onClick={() => fetchProductDetails(produto.sku)}
                >
                  {produto.sku}
                </td>
                <td className="px-4 py-2">{formatCurrency(produto.total)}</td>
                <td className="px-4 py-2">{produto.quantidade || 0}</td>
                <td className="px-4 py-2">{formatCurrency(produto.total / (produto.quantidade || 1))}</td>
                <td className="px-4 py-2">{produto.contribuicao}%</td>
                <td className="px-4 py-2">{produto.acumulado}%</td>
                <td className="px-4 py-2">{produto.categoria}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Modal */}
      {modalVisible && modalData && (
        <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50 p-38">
          <div className="bg-white w-1/2 p-8 rounded-lg shadow-lg relative bg-gray-800">
            <button
              className="absolute top-4 right-4 text-gray-500 hover:text-red-500"
              onClick={closeModal}
            >
              ✕
            </button>
            <h2 className="text-2xl font-bold mb-6 text-center">{modalData.title.pt_BR}</h2>
            <p className="mb-4"><strong>SKU:</strong> {modalData.code}</p>
            <p className="mb-4"><strong>Marca:</strong> {modalData.brand}</p>
            <p className="mb-4"><strong>Condição:</strong> {modalData.condition}</p>
            <div className="mt-6">
              <h3 className="font-bold mb-2">Mídias:</h3>
              <div className="grid grid-cols-3 gap-4">
                {modalData.medias.map((media, index) => (
                  <div key={index} className="flex items-center justify-center text-black">
                    {media.type === 'image/jpeg' ? (
                      <img src={media.reference} alt={media.name} className="w-32 h-32 object-cover rounded-md" />
                    ) : (
                      <a
                        href={media.reference}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-500 underline"
                      >
                        {media.name}
                      </a>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CurvaABCTable;
