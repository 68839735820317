import React, { useContext, useEffect } from 'react';
import axios from 'axios';
import { useAuth } from '../../common/AuthContext';
import { useNavigate } from 'react-router-dom';

const BlingAuthComponent = () => {
  const { authorizationCode, setAccess, setRefresh, refreshCode } = useAuth();


  const navigate = useNavigate();

  useEffect(() => {
    const handleBlingAuth = async () => {    

        
    
      const payload = new URLSearchParams({       
        code: authorizationCode,
      }).toString();

    try {
      const response = await axios.post('https://nerp.com.br/bling/callback', payload, {
        
      });
      console.log('Response:', response.data);
      const accessToken = response.data.access_token;
      console.log(accessToken)
      const refreshToken = response.data.refresh_token;
      setAccess(accessToken)
      localStorage.setItem('bling', accessToken);

      setRefresh(refreshToken)
      localStorage.setItem('refreshCodeToken', refreshToken);
      console.log(accessToken)
      navigate('/dashboard2');
    } catch (error) {
    }
  };

  if (authorizationCode) {
    handleBlingAuth()
  }
}, [ setAccess]);



return null; // No need to render anything for this component
};

export default BlingAuthComponent;