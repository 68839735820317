import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../common/AuthContext';
import BlingAuthComponent from './BlingAuthComponent';

const AuthorizationCallback = () => {
  const { setCode, authorizationCode } = useAuth();
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const handleAuthorizationCallback = async () => {
      try {
        const urlParams = new URLSearchParams(window.location.search);
        const authorization = urlParams.get('code');
        setCode(authorization);
        console.log(authorizationCode)
        setLoading(false);
        
      } catch (error) {
        console.error('Error handling authorization callback:', error);
      }
    };

    handleAuthorizationCallback();
  }, [navigate]);

  return (
    <div>
      {loading ? (
        <div>
          <p>Processing the Authorization Server response...</p>
        </div>
      ) : (
        <div>
          <p>Authorization process completed!</p>
          <BlingAuthComponent/>
          
        </div>
      )}
    </div>
  );
};

export default AuthorizationCallback;
